__webpack_public_path__ = `${window.globalServiceVars.employerReviewsFront.staticHost}/`; // eslint-disable-line

import('./index')
    .then((module) => {
        module.default()({
            unmountApp: false,
            container: document.body,
            identifier: 'common-app',
        });
    })
    .catch((error) => {
        throw new Error('Failed load main module for service: employer-reviews-front', { cause: error });
    });
